@import '../../styles/colors';

.heading {
  font-family: 'Alliance Platt', serif;
  font-weight: normal;
  margin: 0;
  padding: 0;
  line-height: 1.4;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: none;
  // default color
  color: $neutral90;
  -webkit-font-smoothing: antialiased;

  &.h1 {
    font-size: clamp(3.2rem, -0.133rem + 6.667vw, 5.6rem);
  }

  &.h2 {
    font-size: clamp(2.8rem, 0.133rem + 5.333vw, 4.4rem);
  }

  &.h3 {
    font-size: clamp(2.4rem, 0.4rem + 4vw, 3.6rem);
  }

  &.h4 {
    font-size: clamp(1.8rem, 0.8rem + 2vw, 2.4rem);
  }

  &.h5 {
    font-size: clamp(1.6rem, 1.267rem + 0.667vw, 1.8rem);
  }

  span {
    font-weight: inherit;
  }

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 700;
  }

  &.italic {
    font-weight: 400;
    font-style: italic;
  }
}
