@import './colors';

@mixin ripple-effect {
  &:before {
    width: 4rem;
    height: 4rem;
    content: '';
    background-color: transparent;
    position: absolute;
    z-index: 0;
    border-radius: 5rem;
    animation: ripple 0.5s linear 0s 1;
    transform-origin: 50% 50%;
    transition: width 0.25s $easeOutCubic, height 0.25s $easeOutCubic;
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.4);
    border: 1px solid $lime60;
    opacity: 0;
  }

  50% {
    border: 2px solid $lime60;
    opacity: 1;
  }

  75% {
    opacity: 1;
    border: 1px solid $lime60;
  }

  100% {
    transform: scale(1.25);
    border: 0 solid $lime60;
    opacity: 0;
  }
}

@mixin sidenav-hover {
  &:hover {
    cursor: pointer;
    background-color: $neutral90;

    p {
      color: $neutral20;

      :global(body.light) & {
        color: $neutral90 !important;
      }
    }

    :global(body.light) & {
      background-color: $neutral40;
    }
  }

  &:focus,
  &:focus-visible {
    cursor: pointer;
    background-color: $lime60;
    outline: none;

    i {
      color: $neutral90 !important;
    }

    p {
      color: $neutral90;
    }

    :global(body.light) & {
      background-color: $green80;

      i {
        color: $white !important;
      }

      p {
        color: $white;
      }
    }
  }

  &:active {
    background-color: darken($neutral90, 5);

    i {
      color: $neutral20 !important;
    }

    p {
      color: $neutral20;
    }
  }
}

@mixin hoverTransitionBackground($colorFrom, $colorTo) {
  background-color: $colorFrom;
  transition: background-color 0.4s;

  &:hover {
    cursor: pointer;
    background-color: $colorTo;
  }
}

@mixin frostedBackground($opacity) {
  background-color: rgba($white, $opacity);
  backdrop-filter: blur(10px);
}
