// Neutrals
$white: #fff;
$neutralLight20: #f9faf7;
$neutralLight40: #eaebe8;
$neutralLight60: #e5e5e3;
$neutralLight80: #c8c9c5;
$neutral10: #f7f7f7;
$neutral20: #f0f0f0;
$neutral30: #e0e0e0;
$neutral40: #c7c7c7;
$neutral50: #999999;
$neutral60: #737373;
$neutral80: #3d3d3d;
$neutral90: #1a1a1a;

// Semantic
$error10: #ffeeed;
$error50: #e53832;
$success10: #dcfae0;
$success50: #188428;
$warning10: #fffae4;
$warning50: #f7d02d;
$warning70: #e59500;
$warning500: #734f0b;

$green20: #bde5d6;
$green40: #89d7b9;
$green50: #41b989;
$green60: #1d8b66;
$green70: #0b654a;
$green80: #004631;
$green90: #002e20;

$lime20: #f4fadc;
$lime40: #e2f6ac;
$lime50: #ccef6c;
$lime60: #ace119;
$lime70: #8bb616;
$lime80: #5e7b0f;

$blue10: #f7f9ff;
$blue20: #ecf0ff;
$blue40: #c8d4ff;
$blue50: #6d8aed;
$blue60: #1a45db;
$blue70: #1136bb;
$blue80: #092277;

$purple20: #f0ebfe;
$purple30: #e8e2fe;
$purple40: #ddd3fd;
$purple50: #d6cafd;
$purple60: #9170fa;
$purple70: #501df6;
$purple80: #2f07b1;

$pink20: #ffe0ed;
$pink40: #ffb2d2;
$pink50: #ff8abb;
$pink60: #ff61a3;
$pink70: #c21e63;
$pink80: #73113a;

// Card Shadows
$hoverButtonShadow: 0px 2px 4px rgba(27, 61, 92, 0.24);
$raisedCardShadow: 0px 8px 24px rgba(215, 211, 188, 0.32);

// Logo Shadows
$defaultLogoShadow: 0px 5px 8px rgba(37, 42, 45, 0.11);

$shadowLow: 0px 0px 4px rgba(21, 36, 31, 0.04),
  0px 0px 16px rgba(238, 238, 238, 0.16);
$shadowMedium: 0px 0px 6px rgba(21, 36, 31, 0.1),
  0px 0px 16px rgba(238, 238, 238, 0.48);
$shadowHigh: 0px 0px 13px rgba(21, 36, 31, 0.1),
  0px 0px 40px rgba(238, 238, 238, 0.24);
