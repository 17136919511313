@import '../../styles/colors';

.loader {
  width: 4.8rem;
  height: 4.8rem;
  display: block;
  margin: 1.5rem auto;
  position: relative;
  color: $green60;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &:after,
  &:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    width: 2.4rem;
    height: 2.4rem;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: $green60;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }

  &:before {
    background-color: $green80;
    transform: scale(0.5) translate(-4.8rem, -4.8rem);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
