.grid {
  display: grid;
  width: 100%;

  &.columns-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  &.columns-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  &.columns-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  &.columns-8 {
    grid-template-columns: repeat(8, 1fr);
  }
  &.columns-12 {
    grid-template-columns: repeat(12, 1fr);
  }
  &.columns-min-content {
    grid-template-columns: min-content auto;
  }
}

.grid-cell {
  &.span-1-columns {
    grid-column: span 1;
  }

  &.span-2-columns {
    grid-column: span 2;
  }

  &.span-3-columns {
    grid-column: span 3;
  }

  &.span-4-columns {
    grid-column: span 4;
  }

  &.span-5-columns {
    grid-column: span 5;
  }

  &.span-6-columns {
    grid-column: span 6;
  }

  &.span-7-columns {
    grid-column: span 7;
  }

  &.span-8-columns {
    grid-column: span 8;
  }

  &.span-9-columns {
    grid-column: span 9;
  }

  &.span-10-columns {
    grid-column: span 10;
  }

  &.span-11-columns {
    grid-column: span 11;
  }

  &.span-12-columns {
    grid-column: span 12;
  }
}
