@import '../../styles/colors';

.text {
  font-family: 'Alliance Text', sans-serif;
  font-weight: 400;
  line-height: 1.6;
  margin: 0;
  letter-spacing: normal;
  text-decoration: none;
  text-transform: none;
  font-size: 1.6rem;
  // default color
  color: $neutral90;
  -webkit-font-smoothing: antialiased;

  &.span {
    font-size: inherit;
    color: inherit;
  }

  &.regular {
    font-weight: 400;
  }

  &.medium {
    font-weight: 500;
  }

  &.bold {
    font-weight: 700;
  }

  &.italic {
    font-weight: 400;
    font-style: italic;
  }

  &.regular-all-caps {
    font-weight: 400;
    text-transform: uppercase;
  }

  &.medium-all-caps {
    font-weight: 500;
    text-transform: uppercase;
  }

  &.italic-all-caps {
    font-weight: 400;
    font-style: italic;
    text-transform: uppercase;
  }

  &.success {
    color: $success50;
  }

  &.failed {
    color: $error50;
  }

  &.warning {
    color: $warning50;
  }

  &.in_process {
    color: $neutral60;
  }

  &.disabled {
    color: $neutral60;
  }
}
