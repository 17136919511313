// width sizes
$bp-1440: 1440px;
$bp-1280: 1280px;
$bp-1024: 1024px;
$bp-960: 960px;
$bp-800: 800px;
$bp-640: 640px;
$bp-520: 520px;
$bp-320: 320px;

// Media Queries
@mixin respond-to($media) {
  @if $media == w320 {
    @media only screen and (max-width: $bp-320) {
      @content;
    }
  } @else if $media == w520 {
    @media only screen and (max-width: $bp-520) {
      @content;
    }
  } @else if $media == w640 {
    @media only screen and (max-width: $bp-640) {
      @content;
    }
  } @else if $media == w800 {
    @media only screen and (max-width: $bp-800) {
      @content;
    }
  } @else if $media == w960 {
    @media only screen and (max-width: $bp-960) {
      @content;
    }
  } @else if $media == w1024 {
    @media only screen and (max-width: $bp-1024) {
      @content;
    }
  } @else if $media == w1280 {
    @media only screen and (max-width: $bp-1280) {
      @content;
    }
  } @else if $media == w1440 {
    @media only screen and (max-width: $bp-1440) {
      @content;
    }
  }
}

@mixin respond-height($media) {
  @if $media == w320 {
    @media only screen and (max-height: $bp-320) {
      @content;
    }
  } @else if $media == w520 {
    @media only screen and (max-height: $bp-520) {
      @content;
    }
  } @else if $media == w640 {
    @media only screen and (max-height: $bp-640) {
      @content;
    }
  } @else if $media == w800 {
    @media only screen and (max-height: $bp-800) {
      @content;
    }
  } @else if $media == w960 {
    @media only screen and (max-height: $bp-960) {
      @content;
    }
  } @else if $media == w1024 {
    @media only screen and (max-height: $bp-1024) {
      @content;
    }
  } @else if $media == w1280 {
    @media only screen and (max-height: $bp-1280) {
      @content;
    }
  } @else if $media == w1440 {
    @media only screen and (max-height: $bp-1440) {
      @content;
    }
  }
}
