/* Font Family */
@font-face {
  font-family: 'Balto Web';
  font-display: fallback;
  src: url('../public/fonts/balto/baltoweb-book.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Balto Web';
  font-display: fallback;
  src: url('../public/fonts/balto/baltoweb-bookitalic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Balto Web';
  font-display: fallback;
  src: url('../public/fonts/balto/baltoweb-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Balto Web';
  font-display: fallback;
  src: url('../public/fonts/balto/baltoweb-mediumitalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Ivar Display';
  font-display: fallback;
  src: url('../public/fonts/ivar/IvarDisplay-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ivar Display';
  font-display: fallback;
  src: url('../public/fonts/ivar/IvarDisplay-Italic.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Ivar Display';
  font-display: fallback;
  src: url('../public/fonts/ivar/IvarDisplay-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Ivar Display';
  font-display: fallback;
  src: url('../public/fonts/ivar/IvarDisplay-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

/* Alliance Text */
@font-face {
  font-family: 'Alliance Text';
  font-display: fallback;
  src: url('../public/fonts/alliance-text/AllianceText-Regular.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Alliance Text';
  font-display: fallback;
  src: url('../public/fonts/alliance-text/AllianceText-RegularItalic.woff')
    format('woff');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Alliance Text';
  font-display: fallback;
  src: url('../public/fonts/alliance-text/AllianceText-Medium.woff')
    format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Alliance Text';
  font-display: fallback;
  src: url('../public/fonts/alliance-text/AllianceText-MediumItalic.woff')
    format('woff');
  font-weight: 500;
  font-style: italic;
}

/* Alliance Platt */
@font-face {
  font-family: 'Alliance Platt';
  font-display: fallback;
  src: url('../public/fonts/alliance-platt/AlliancePlatt-Bold.woff')
    format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Alliance Platt';
  font-display: fallback;
  src: url('../public/fonts/alliance-platt/AlliancePlatt-BoldItalic.woff')
    format('woff');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Alliance Platt';
  font-display: fallback;
  src: url('../public/fonts/alliance-platt/AlliancePlatt-Medium.woff')
    format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Alliance Platt';
  font-display: fallback;
  src: url('../public/fonts/alliance-platt/AlliancePlatt-MediumItalic.woff')
    format('woff');
  font-weight: 400;
  font-style: italic;
}

html,
body {
  font-family: 'Alliance Text', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  height: 100%;
  font-weight: 400;
  background-color: #fff;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#root {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media print and (color) {
  * {
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
  img {
    page-break-inside: avoid;
  }
}
