@import '../../styles/colors';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.button {
  background-color: initial;
  color: $white;
  outline: none;
  border: none;
  border-radius: 0.8rem;
  font-weight: 400;
  white-space: nowrap;
  position: relative;
  transition: color 0.4s;

  &:hover {
    cursor: pointer;
    color: $neutral20;
  }

  &.contained {
    border: 1px solid transparent;
    @include hoverTransitionBackground($green80, $green60);

    &.rounded {
      border-radius: 3rem;
    }

    &.lime {
      color: $neutral90;
      @include hoverTransitionBackground($lime60, $lime70);
    }

    &:disabled {
      background-color: $neutral40;
      cursor: not-allowed;
      color: darken($neutral40, 15);

      &:hover {
        background-color: $neutral40;
        cursor: not-allowed;
      }
    }
  }

  &.outlined,
  &.outlined-dark {
    @include frostedBackground(0.1);
    border: 1px solid $white;
    color: $white;
    transition: background-color 0.4s;
    font-weight: 500;

    &:hover {
      background-color: rgba($white, 0.3);
    }

    &.rounded {
      border-radius: 3rem;
    }

    &.lime {
      border: 1px solid $lime60;
      color: $lime60;
    }

    &:disabled {
      background-color: $neutral40;
      cursor: not-allowed;

      &:hover {
        background-color: $neutral40;
        cursor: not-allowed;
      }
    }
  }

  &.outlined-dark {
    border: 1px solid $neutral90;
    background-color: rgba($neutral40, 0.1);
    color: $neutral90;
    transition: background-color 0.4s;
    font-weight: 500;

    &:hover {
      background-color: rgba($neutral60, 0.3);
    }

    &:disabled {
      background-color: $neutral40;
      cursor: not-allowed;
      color: $white;
      border: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }

  &.tertiary {
    border-radius: 0;
  }

  &.tertiary-dark {
    color: $neutral60;

    &:hover {
      color: $neutral80;
    }
  }

  &.loading {
    color: $neutral30;

    > span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
