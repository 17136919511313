@import '../styles/colors';
@import '../styles/breakpoints';

.footerSection {
  background-color: $neutral90;
  border-bottom: 3px solid $lime60;

  .footerSectionInner {
    width: calc(100% - 4.8rem);
    max-width: 102.4rem;
    margin: 0 auto;

    @include respond-to(w640) {
      margin: 0;
      width: 100%;
    }
  }

  .footerLinks a {
    color: $neutral60;
    text-decoration: none;
    will-change: color;
    transition: color 0.4s;

    &:hover {
      color: $neutral50;
    }
  }
}
