@import './easings';

@mixin animateFadeInFromTop($delay) {
  will-change: opacity, transform;
  opacity: 0;
  animation: fadeInFromTop 0.6s $easeOutCubic $delay forwards;
}

@mixin animateFadeInFromBottom($delay) {
  will-change: opacity, transform;
  opacity: 0;
  animation: fadeInFromBottom 0.6s $easeOutCubic $delay forwards;
}

@mixin animateFadeInFromLeft($delay) {
  will-change: opacity, transform;
  opacity: 0;
  animation: fadeInFromLeft 0.6s $easeOutCubic $delay forwards;
}

@mixin animateFadeInFromRight($delay) {
  will-change: opacity, transform;
  opacity: 0;
  animation: fadeInFromRight 0.6s $easeOutCubic $delay forwards;
}

@mixin animateFadeIn($delay) {
  will-change: opacity;
  opacity: 0;
  animation: fadeIn 0.6s linear $delay forwards;
}

// keyframes
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInFromTop {
  from {
    opacity: 0;
    transform: translateY(-24px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromBottom {
  from {
    opacity: 0;
    transform: translateY(24px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromLeft {
  from {
    opacity: 0;
    transform: translateX(-24px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInFromRight {
  from {
    opacity: 0;
    transform: translateX(24px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
