@import './styles/colors';
@import './styles/breakpoints';
@import './styles/mixins';
@import './styles/animations';
@import './styles/easings';

@mixin contentArea {
  max-width: 144rem;
  margin: 0 auto;
  position: relative;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100lvh;
  background-color: darken($blue80, 10);
  display: flex;
  flex-direction: column;
  // justify-content: center;
  overflow: hidden;
  padding: 12rem 0 16rem;

  @include respond-to(w800) {
    padding: 12rem 0;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.4s;
    background: lighten($neutral90, 1);
    background: radial-gradient(
      65vw circle at var(--mouse-x) var(--mouse-y),
      rgba($purple60, 0.25) 0%,
      rgba($purple60, 0) 100%
    );
    z-index: 1;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }

  .animatedBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.2;
    overflow: hidden;
    z-index: 1;
    pointer-events: none;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .mainContent {
    // padding: clamp(10rem, 20vw, 24rem) 4.8rem 4rem;
    padding: 8rem;
    z-index: 2;

    @include contentArea;
    @include animateFadeInFromBottom(1s);

    @include respond-to(w800) {
      padding: 4rem 3.2rem;
    }

    h1,
    h2 {
      line-height: 1.2;
      font-weight: 100;
    }

    .subtitle {
      max-width: 64rem;
      margin: 0 auto;
    }

    .buttonGroup {
      @include animateFadeInFromBottom(1.2s);
    }
  }

  .introHeading {
    position: relative;
    opacity: 1;
    transition: transform 0.6s $easeOutCubic, opacity 0.6s $easeOutCubic,
      filter 0.6s $easeOutCubic;
    filter: blur(0);
    will-change: transform, opacity, filter;
    transform: scale(1) translate(0, 0);
    text-align: center;

    &.showMadlib {
      transform: scale(0.8) translate(0, -4rem);
      opacity: 0.5;
      filter: blur(0.4rem);
      pointer-events: none;
    }
  }

  .madlibContainer {
    position: absolute;
    z-index: 5;
    pointer-events: none;

    @include animateFadeInFromBottom(1.4s);

    @include respond-to(w960) {
      padding: 0 2.4rem;
    }

    .madlibInner {
      pointer-events: all;
      z-index: 2;
      will-change: transform, opacity;
      transform: translate(0, calc(100vh - 44vh));
      filter: blur(0.4rem);
      opacity: 0.8;
      transition: transform 0.6s $easeOutCubic, opacity 0.6s;

      pointer-events: none;
      max-width: 96rem;
      min-height: 33rem;
      margin: 0 auto;
      border-radius: 0.8rem;
      box-shadow: 0 4px 2rem rgba(black, 0.25);
      overflow: hidden;

      @include frostedBackground(0.9);

      @include respond-to(w800) {
        transform: translate(0, calc(100vh - 40vh));
      }
    }

    &.showMadlib {
      .madlibInner {
        pointer-events: all;
        filter: blur(0);
        transform: scale(1) translate(0, 0);
        opacity: 1;
      }
    }
  }
}

.mainContainer {
  overflow: hidden;
}

.section {
  position: relative;

  &.claimCredits {
    z-index: 6;

    &:before {
      content: '';
      position: absolute;
      left: -5%;
      top: -4rem;
      width: 110%;
      height: 10rem;
      background-color: $white;
      z-index: 2;
      transform: rotate(-3deg);
      box-shadow: 0 -0.4rem 2.4rem rgba(black, 0.25);

      @include respond-to(w640) {
        top: -2rem;
      }
    }
  }

  .sectionInView {
    position: relative;
  }

  &.howItWorks {
    background-color: $neutral10;
  }
}

.sectionContent {
  position: relative;
  padding: 4.8rem 4.8rem 9.6rem;
  z-index: 3;

  &.claimCredits {
    z-index: 3;
    position: relative;
    background-color: $white;
  }

  &.howItWorks {
    max-width: 128rem;
    position: relative;
  }

  > div {
    max-width: 102.4rem;
    margin: 0 auto;
  }

  h3 {
    max-width: 64rem;
    margin: 0 auto;
    text-align: center;
  }

  @include respond-to(w640) {
    padding: 4rem 3.2rem;
  }
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.4rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 1.6rem 3.2rem;
  z-index: 9;
  will-change: background-color, backdrop-filter;
  transition: background-color 0.4s, backdrop-filter 0.4s;

  @include animateFadeInFromTop(0.8s);

  .loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 3px;
    background-color: $lime60;
    will-change: width, opacity;
    animation: loaderProgress 5s linear 1s forwards;
    opacity: 1;
    transition: opacity 0.4s;

    &.showMadlib {
      opacity: 0;
    }
  }

  &.scrolled {
    background-color: rgba($blue40, 0.2);
    backdrop-filter: blur(10px);
  }

  .logoGroup {
    position: relative;
    width: 16rem;
    height: 4.2rem;

    .darkLogo,
    .lightLogo {
      position: absolute;
      will-change: opacity;
      transition: opacity 0.4s;
    }

    .lightLogo {
      opacity: 0;
    }

    .darkLogo {
      opacity: 1;
    }
  }

  .logInBtn {
    color: $neutral90;
    will-change: color;
    transition: color 0.4s;
  }

  :global(.section-in-view) & {
    &.scrolled {
      @include frostedBackground(0.1);
    }

    .lightLogo {
      opacity: 1;
    }

    .darkLogo {
      opacity: 0;
    }

    .logInBtn {
      color: $white;
    }
  }
}

@keyframes loaderProgress {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.estimatesContainer {
  position: relative;
  // min-height: 100dvh;
  min-height: 100%;
  justify-content: center;
  align-items: center;

  .estimateContent {
    position: relative;
    max-width: 122.8rem;
    margin: 0 auto;

    h4 {
      font-size: clamp(1.8rem, 1.086rem + 1.19vw, 2.8rem);
    }

    h5 {
      font-size: clamp(1.6rem, 1.314rem + 0.476vw, 2rem);
    }
  }

  .estimateList {
    @include frostedBackground(0.8);
    border: 1px solid $green20;
    border-radius: 8px;
    will-change: border;
    transition: border 0.4s;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.8rem 2rem;
    gap: 3.2rem;

    @include respond-to(w960) {
      padding: 0;
      border: none;
      border-bottom: 1px solid $neutral40;
      background-color: transparent;
      border-radius: 0;
      backdrop-filter: none;
    }

    @include respond-to(w520) {
      flex-basis: 100% !important;
    }

    &:hover {
      border: 1px solid $green60;
    }
  }

  .estimateValue {
    font-size: clamp(1.6rem, 1.733rem + 1.333vw, 2.4rem) !important;
  }

  .contentCta {
    // height: 100dvh;
    position: sticky;
    top: 0;

    @include respond-to(w960) {
      height: auto;
      position: relative;
      top: auto;
    }
  }

  .claimBtn {
    position: relative;

    .confetti {
      position: absolute;
      top: -16rem;
      left: -24rem;
      pointer-events: none;

      canvas {
        width: 64rem;
      }
    }
  }
}

.inputValues {
  .inputField {
    position: relative;

    p {
      position: absolute;
      left: 0.8rem;
      top: 50%;
      transform: translate(0, -50%) scale(1);
      transition: transform 0.4s $easeInOutSmooth, color 0.4s;
      z-index: 3;
      pointer-events: none;
      will-change: transform, color;
      background-color: $white;
      padding: 0 0.4rem;
      color: $neutral60;
      white-space: nowrap;
    }

    &:focus-within,
    &.filled {
      p {
        transform: scale(0.85) translate(-2.4rem, -4.6rem);
        color: $neutral60 !important;
      }

      .input.currencyInput {
        span {
          opacity: 1;
        }
      }
    }
  }

  .input {
    border-radius: 0.8rem;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 0;
      height: 3px;
      background-color: $green60;
      will-change: width;
      transition: width 0.4s $easeInOutSmooth;
      bottom: 0;
      left: 0;
    }

    &:focus-within {
      &:after {
        width: 100%;
      }
    }

    &.currencyInput {
      span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
        font-size: 2.4rem;
        font-weight: 700;
        color: lighten($green80, 5);
        padding: 1.6rem 0 1.2rem 1.6rem;
        opacity: 0;
        will-change: opacity;
        transition: opacity 0.4s;
      }

      input {
        padding: 1.6rem 0 1.2rem 3.2rem;
      }
    }
  }

  input {
    width: 100%;
    border: none;
    font-size: 2.4rem;
    font-weight: 700;
    padding: 1.6rem 0 1.2rem 1.6rem;
    border: 3px solid $neutral20;
    color: lighten($green80, 5);
    border-radius: 0.8rem;

    &:focus,
    &:focus-visible {
      outline: none;
    }
  }
}

$containerMinHeight: 54rem;

.estimateContainer {
  position: absolute;
  z-index: 5;
  pointer-events: none;
  height: calc(100dvh - 36rem);
  min-height: calc($containerMinHeight + 2rem);

  .logomark {
    filter: grayscale(1);
    will-change: filter, opacity;
    transition: filter 0.4s, opacity 0.4s;
    opacity: 0.25;
  }

  @include animateFadeInFromBottom(1.4s);

  .estimateInner {
    pointer-events: all;
    background-color: $white;
    box-shadow: 0 4px 2.4rem rgba(black, 0.25);
    width: 100%;
    max-width: 40rem;
    margin: 0 auto;
    border-radius: 8px;
    padding: 3.2rem 2.4rem 2rem;
    max-height: 60rem;
    min-height: $containerMinHeight;
    transform: translate(0, calc(100% - 3.2rem));
    position: relative;
    z-index: 2;
    will-change: transform, width;
    transition: transform 0.6s $easeOutCubic, max-width 0.6s $easeOutCubic;

    @media (max-aspect-ratio: 16/7) {
      transform: translate(0, calc(100dvh - 28rem));
    }

    @include respond-height(w640) {
      transform: translate(0, calc(100% - 9.2rem));
    }

    &.showEstimates {
      width: calc(100% - 6.4rem);
      max-width: 96rem;

      @include respond-to(w960) {
        max-height: fit-content;
        min-height: auto;
      }
    }

    .innerContent {
      display: flex;
      flex-direction: column;
      gap: 32px;
      height: 100%;

      h4 {
        font-size: clamp(1.8rem, 1.086rem + 1.19vw, 2.8rem);
      }

      h5 {
        font-size: clamp(1.6rem, 1.314rem + 0.476vw, 2rem);
      }

      .question {
        border: 3px solid $neutral20;
        padding: 1.2rem 1.2rem 1.2rem 0.8rem;
        border-radius: 8px;

        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          padding-top: 4px;
        }

        .checkbox:hover .checkboxCheck {
          background: $green50;
        }

        .checkboxInput {
          width: 0;
          height: 0;
          opacity: 0;
        }

        .checkboxInput:checked + .checkboxCheck {
          background: $green70;
          stroke-dashoffset: 0;
        }

        .checkboxCheck {
          border: 1px solid $green70;
          background: $neutral30;
          stroke: #f9f9f9;
          stroke-dasharray: 25;
          stroke-dashoffset: 25;
          stroke-linecap: round;
          stroke-width: 2px;
          border-radius: 2px;
          fill: none;
          transition: background 0.4s, stroke-dashoffset 0.6s;
        }
        p {
          position: relative;
        }
      }
    }

    .innerContent,
    .btnGroup {
      opacity: 0;
      will-change: opacity;
      transition: opacity 0.4s 0.4s;
    }

    .overlayTrigger {
      background-color: rgba(black, 0.15);
      backdrop-filter: blur(0.4rem);
      border-radius: 8px;
      position: absolute;
      height: calc(100% - 4rem);
      width: calc(100% - 4rem);
      box-shadow: 0 4px 1.2rem rgba(black, 0.1);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 5;
      padding: 3.2rem;
      will-change: background-color, backdrop-filter, opacity;
      transition: background-color 0.4s, backdrop-filter 0.4s, opacity 0.6s;
      pointer-events: none;

      &:hover {
        background-color: rgba(black, 0.05);
        backdrop-filter: blur(0.2rem);
      }
    }
  }

  &.showMadlib {
    .estimateInner {
      transform: translate(0, 0);
    }

    .overlayTrigger {
      background-color: transparent;
      backdrop-filter: blur(0);
      opacity: 0;
    }

    .logomark {
      filter: grayscale(0);
      opacity: 1;
    }

    .innerContent,
    .btnGroup {
      opacity: 1;
    }
  }
}
